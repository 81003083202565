import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus'; // 导入 Element Plus 组件库
// import 'element-plus/lib/theme-chalk/index.css'; // 导入 Element Plus 的样式文件
import axios from 'axios';

const app = createApp(App);

app.config.productionTip = false;
app.config.globalProperties.$axios = axios;
axios.defaults.baseURL = '/api';

app.use(router);
app.use(ElementPlus); // 注册 Element Plus 组件库

app.mount('#app');
