<!-- eslint-disable vue/multi-word-component-names -->


<template>
    <div class="hello">
      <h1>{{ message }}</h1>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        message: 'Hello Vue!'
      }
    }
  }
  </script>
  
  <style scoped>
  .hello {
    text-align: center;
    margin-top: 60px;
  }
  </style>