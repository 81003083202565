import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '@/components/HelloWorld.vue';
import MainPage from '@/components/MainPage.vue';
import DemoVue from '@/components/DemoVue.vue';
const routes = [
    {
        path: '/demo',
        name: 'DemoVue',
        component: DemoVue
    },
    {
        path: '/hello',
        name: 'HelloWorld',
        component: HelloWorld
    },
    {
        path: '/',
        name: 'MainPage',
        component: MainPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
