<template>
  <div>
    <h2>设备状态信息</h2>
    <div class="data-fetch-time">
      <p>数据获取时间: {{ dataFetchTime }}</p>
    </div>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>公寓</th>
          <th>位置</th>
          <th>序列号</th>
          <th>通道</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(device, index) in deviceStatusList" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ device.apartment }}</td>
          <td>{{ device.position }}</td>
          <td>{{ device.sequence }}</td>
          <td>{{ device.channel }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024058258号-1</a>
</template>

<script>
export default {
  data() {
    return {
      deviceStatusList: [],
      dataFetchTime: null
    };
  },
  mounted() {
    // 第一次加载数据
    this.fetchData();
    // 每10秒请求一次数据
    setInterval(() => {
      this.fetchData();
    }, 10000);
  },
  methods: {
    fetchData() {
      this.$axios
        .get("https://www.lynucdz.cn/DeviceStatus/deviceStatusALL")
        .then((res) => {
          this.deviceStatusList = res.data;
          this.dataFetchTime = new Date().toLocaleString();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString();
    }
  }
};
</script>

<style scoped>
.data-fetch-time {
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>